import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="avatar">
        <img src="sophieloreti.jpg" alt="Sophie Loreti" className="img" />
        <div className="name">Sophie Loreti</div>
      </div>
    </div>
  );
}

export default App;
